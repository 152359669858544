<template>
  <CRow>
    <CCol col="12" xl="12">
      <transition name="slide">
        <CRow>
          <CCol sm="12">
            <CCard>
              <CCardHeader>
                <CCardTitle>Details of Part Category #{{ part_category.name }}</CCardTitle>
                <div class="card-header-actions"></div>
              </CCardHeader>

              <CCardBody>
                <CRow>
                  <CCol sm="6">
                    <CInput
                      name="name"
                      label="Name"
                      placeholder="Enter the name of the part"
                      v-model="part_category.name"
                    />
                  </CCol>
                
                  <CCol sm="6">
                    <CSelect
                      label="Type"
                      placeholder="select"
                      :options="[
                        { label: 'Bicycle', value: 'bicycle' },
                        { label: 'Accessories', value: 'accessories' },
                        { label: 'E-bike', value: 'e-bike' },
                      ]"
                      :value.sync="part_category.product_type"
                    />
                  </CCol>
                
                  <CCol sm="6">
                    <CInputFile
                      name="image"
                      label="Image"
                      description="Maximum total upload size is 1MB.
                    Images can be compressed using: imagecompressor.com"
                      v-on:change="setImage"
                      required
                    />
                    <img width="50" :src="storageURL + part_category.image" aria-busy="" />
                  </CCol>
                </CRow>
              </CCardBody>
            </CCard>
          </CCol>

          <CCol sm="12">
            <CButton
              variant="outline"
              type="submit"
              color="primary"
              @click="updatePartCategory"
            >
              <CIcon name="cil-check-circle" />
              Update
            </CButton>
            <CButton
              class="float-right"
              variant="outline"
              type="submit"
              color="danger"
              @click="deletePartCategory"
            >
              <CIcon name="cil-trash" /> Delete
            </CButton>
          </CCol>
        </CRow>
      </transition>
    </CCol>
  </CRow>
</template>

<script>
import PartCategoryAPI from "/app/src/api/part-category.js";

export default {
  name: "Detail",
  data: () => {
    return {
      part_category: {},
      new_image: {},
      storageURL: process.env.VUE_APP_STORAGE_URL,
      partCategoryAPI: new PartCategoryAPI(),
    };
  },
  created: function () {
    this.getPartCategory(this.$route.params.id);
  },
  methods: {
    getPartCategory: function (id) {
      this.$store.dispatch("loading");
      let self = this;
      self.partCategoryAPI
        .detail(id)
        .then((part_category) => {
          this.$store.dispatch("stopLoading");
          self.part_category = part_category;
        })
        .catch((error) => {
          this.$store.dispatch("stopLoading");
          console.log(error);
          this.$alert.show({ type: "danger", message: error });
        });
    },
    validateSubmission: function () {
      if (this.part_category.name.length < 1) return "Name is required";
      if (this.part_category.product_type.length < 1) return "Product type is required";
      return true;
    },
    updatePartCategory: function (id) {
      let message = this.validateSubmission();
      if (message !== true) {
        this.$alert.show({ type: "danger", message: message });
        return;
      }

      this.$store.dispatch("loading");
      let self = this;
      let data = {
        id: this.part_category.id,
        name: this.part_category.name,
        product_type: this.part_category.product_type,
        image: this.part_category.image,
      };
      this.partCategoryAPI
        .update(data, this.new_image)
        .then((part) => {
          self.$store.dispatch("stopLoading");
          self.$alert.show({
            type: "success",
            message: "Part has been updated",
          });
          self.part = part;
        })
        .catch((error) => {
          console.log(error);
          this.$store.dispatch("stopLoading");
          this.$alert.show({ type: "danger", message: error });
        });
    },
    deletePartCategory: function (id) {
      const params = {
        title: "Confirm",
        text: "Are you sure you want to delete?",
        onConfirm: () => {
          this.$store.dispatch("loading");
          this.partCategoryAPI
            .delete(this.$route.params.id)
            .then((result) => {
              this.$store.dispatch("stopLoading");
              console.log(result);
              this.$router.push({ path: "/manager/part-categories" });
            })
            .catch((error) => {
              this.$store.dispatch("stopLoading");
              console.log(error);
              this.$alert.show({ type: "danger", message: error });
            });
        },
      };
      this.$confirm.show(params);
    },
    setImage: function (files, e) {
      this.new_image = files[0];
      console.log(this.new_image);
    },
    deleteImage: function () {
      this.part_category.image = "";
    },
  },
};
</script>

<style scoped>
.logo-img img {
  width: 100%;
}
.part-images img {
  width: 100%;
}

.delete-img-btn {
  position: absolute;
  top: -10px;
  right: 5px;
  z-index: 999;
  color: white;
  background-color: red;
  padding: 5px;
  border-radius: 50px;
  width: 25px;
  height: 25px;
  border: none;
}

.delete-img-btn svg {
  margin-top: -9px;
}
</style>
